.cart_page{
/* Container Styles */

.radio-group{
    display: flex;
}
.payment-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    font-family: 'Arial', sans-serif;
  }
  
  /* Title */
  h2 {
    text-align: center;
    margin-bottom: 25px;
    font-size: 28px;
    color: #333;
  }
  
  /* Radio Group */
  .radio-group {
    display: flex;
    gap: 15px;
  }
  
  /* Radio Label */
  .radio-label {
    display: flex;
    align-items: center;
    padding: 12px;
    border: 2px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #f7f7f7;
    position: relative;
  }
  
  .radio-label:hover {
    border-color: #007bff;
    background-color: #eef6ff;
  }
  
  .radio-label.selected {
    border-color: #007bff;
    background-color: #e0f0ff;
  }
  
  /* Custom Radio Button */
  .custom-radio {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ddd;
    background-color: #fff;
    margin-right: 15px;
    transition: all 0.3s ease;
  }
  
  .radio-label.selected .custom-radio {
    border-color: #007bff;
    background-color: #007bff;
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
  }
  
  /* Hide the default radio button */
  .radio-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Submit Button */
  .submit-button {
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    border: none;
    border-radius: 10px;
    background-color: #007bff;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .submit-button:active {
    background-color: #00408b;
    transform: translateY(0);
  }
}