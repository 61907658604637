a {
  cursor: pointer;
}
.carousel-item {
  display: block;
}

.sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.floating-whatsapp-chatbox{

  img{
    object-fit: contain;
  }
}