.contact-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .hero-section {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .hero-section h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  
  .hero-section p {
    font-size: 1.2em;
    color: #777;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form input,
  .contact-form select,
  .contact-form textarea {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }
  
  .contact-form button {
    padding: 15px;
    font-size: 1em;
    border: none;
    background-color: #5cb85c;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #4cae4c;
  }
  
  .company-info {
    margin-top: 50px;
  }
  
  .company-info h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .company-info p {
    font-size: 1em;
    margin-bottom: 10px;
  }
  
  .company-info i {
    margin-right: 10px;
    color: #5cb85c;
  }
  
  .social-media {
    margin-top: 40px;
    text-align: center;
  }
  
  .social-media a {
    margin: 0 10px;
    font-size: 1.5em;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .social-media a:hover {
    color: #5cb85c;
  }
  