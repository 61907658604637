.header-class{
.my_order_header{
    float: inline-end;
      color: #48e148 !important;
      font-weight: 700;
  }
.my_order_header:hover{
    color: #48e148 !important;
text-decoration: underline;
  }

  a.text-dark:hover, a.text-dark:focus {
    color: #48e148 !important;
  }
}

.header-link{
    display: flex;
    justify-content: space-between;
}

.display-block{
    display: inline !important;
}