        .orders_body {
            font-family: Arial, sans-serif;
            background-color: #f8f9fa;
            margin: 0;
            padding: 20px;
            display: block !important;
            justify-content: center;
            align-items: center;
            display: ruby-text;
            text-align: start;

            .card {
              background-color: white;
              border: 1px solid #ddd;
              border-radius: 8px;
              padding: 20px;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              margin: 40px;
          }
  
          .card img {
              width: 100%;
              border-radius: 8px 8px 0 0;
          }
  
          .card-title {
              
          }
  
          .card-subtitle {
              color: #6c757d;
              margin-bottom: 15px;
              font-size: 1em;
          }
  
          .card-text {
              font-size: 0.95em;
              color: #212529;
              margin-bottom: 15px;
          }
  
          .card-button {
              padding: 10px 20px;
              background-color: #6c757d;
              color: white;
              text-decoration: none;
              border-radius: 5px;
              display: inline-block;
          }
  
          .card-button:hover {
              background-color: #5a6268;
          }
  
          table {
              font-family: arial, sans-serif;
              border-collapse: collapse;
              width: 100%;
            }
            
            td, th {
              border: 1px solid #dddddd;
              text-align: left;
              padding: 8px;
            }
            
            tr:nth-child(even) {
              background-color: #dddddd;
            }
  
            .amount{
              width: 100%;
              text-align: end;
              font-weight: 700;
              color: green;
            }
  
           

        }

        .row-orders {
          display: grid !important;
          grid-template-columns: repeat(3,1fr);
          grid-gap: 10px;
          padding:10px;
          background: #eee;
        }

       

        