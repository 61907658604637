.heart-container {
  cursor: pointer;
}

.heart-like {
  fill: none;
  stroke: #000;
  stroke-width: 2px;
  transition: fill 0.3s ease, transform 0.3s ease;
}

.heart-like.liked {
  fill: red;
  transform: scale(1.3);
}

.heart-like:hover {
  transform: scale(1.1);
}

@media only screen and (max-width: 767px) {

.showInMobileYoutube{
  display: block !important;
}
.showInMobile{
  display: none !important;
}
}

@media only screen and (min-width: 767px) {
  .showInMobileYoutube{
    display: none !important;
  }
  .showInMobile{
    display: block !important;
  }
}
.outOfStock{
  background: rgba(255, 255, 255, 0.7);
}