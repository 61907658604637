.navbar {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;


.profile {
    position: relative;
}

.profile-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-width: 150px;
}

.dropdown-item {
    padding: 10px 15px;
    text-decoration: none;
    color: #343a40;
    display: block;
}

.dropdown-item:hover {
    background-color: #f1f1f1;
}
}